import React, { useEffect } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalPlayMore.css";

export default function ModalPlayMore({
    handleNo,
    handleShowModal,
    handleYes,
    isGameOver,
    score,
    scorePC,
    showModal,
}) {
    useEffect(() => {
        if (isGameOver) {
            const btnTrigger = document.getElementById("modal-trigger");
            setTimeout(() => {
                btnTrigger.click();
            }, 400);
        }
    }, [isGameOver]);

    return (
        <div className="modalPlayMore">
            <button
                className="d-none"
                id="modal-trigger"
                variant="primary"
                onClick={handleShowModal}
            >
                Launch Modal
            </button>

            <Modal
                animation={false}
                centered
                className="text-center text-white"
                onHide={handleNo}
                show={showModal}
            >
                <Modal.Header className="bg-black">
                    <Modal.Title
                        className="display-4 fw-bold modalTitle"
                        style={{ width: "100%" }}
                    >
                        GAME OVER!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark">
                    <p
                        className={`fs-1 fw-bold${
                            score !== null &&
                            scorePC !== null &&
                            score > scorePC
                                ? " won"
                                : score < scorePC
                                ? " lost"
                                : " draw"
                        }`}
                    >
                        {`${
                            score > scorePC
                                ? "🏆 WIN! 🏆"
                                : score < scorePC
                                ? "😭 LOSS 😭"
                                : "🤷🏻‍♂️ DRAW 🤷🏻‍♂️"
                        }`}
                    </p>
                    <p className="fs-5 fw-normal">
                        Would you like to play one more game?
                    </p>
                </Modal.Body>
                <Modal.Footer className="bg-black d-flex justify-content-around">
                    <button className="arrowBtn no" onClick={handleNo}>
                        NO
                    </button>
                    <button className="arrowBtn yes" onClick={handleYes}>
                        YES
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
