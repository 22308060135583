import "./Message.css";

export default function Message({ isItAfterShot, message }) {
    return (
        <>
            {!isItAfterShot ? (
                <p className="message">{message}</p>
            ) : (
                <p className="message result">{message}</p>
            )}
        </>
    );
}

// `message${isItAfterShot ? " " + "result" : ""}`
