import "./Goal.css"

export default function Goal() {
  return (
    <div className="goal-container">
        <div className="goal">
            
        </div>
    </div>
  )
}
