import "./Greeting.css";

export default function Greeting({ startGame }) {
    return (
        <div className="greeting">
            <h1>RISKY KICK!</h1>
            <button className="playBtn" onClick={() => startGame()}>
                PLAY
            </button>
            <footer>
                <p className="text-secondary">
                    By the help of a face of{" "}
                    <a href="https://github.com/necipallef">this guy</a> made by{" "}
                    <a href="https://hakanayata.com">this guy</a>.
                </p>
            </footer>
        </div>
    );
}
