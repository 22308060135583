import { useCallback, useEffect, useState } from "react";
import Field from "./components/field/Field";
import Greeting from "./components/greeting/Greeting";
import Scoreboard from "./components/scoreboard/Scoreboard";
import { GiGoalKeeper } from "react-icons/gi";
import "./index.css";
import whistle from "../src/assets/whistle.wav";
import kickSound0 from "../src/assets/ball-kick0.wav";
import kickSound1 from "../src/assets/ball-kick1.wav";
import winSound from "../src/assets/win-cartoon.mp3";
import lossSound from "../src/assets/loss-cartoon.mp3";

//#region environment
// env (check .env file)
// const isDev = process.env.REACT_APP_DEV === "true";

// function logMessage(msg) {
//     if (isDev) {
//         console.log(msg);
//     }
// }
//#endregion

export default function App() {
    // initial states
    const [chartArr, setchartArr] = useState([]);
    const [isGameOver, setIsGameOver] = useState(false);
    const [isItAfterShot, setIsItAfterShot] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRoleSwitched, setIsRoleSwitched] = useState(false);
    const [isWaitingUser, setIsWaitingUser] = useState(true);
    const [message, setMessage] = useState("Choose a side to shoot!");
    const [moveBallTo, setMoveBallTo] = useState("");
    const [moveGKTo, setmoveGKTo] = useState("");
    const [score, setScore] = useState(0);
    // change name to scoreComp
    const [scorePC, setScorePC] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [kickSound, setKickSound] = useState(false);

    // todo: user vs computer

    const calculateWinner = useCallback(() => {
        const chartArrLength = chartArr.length;

        // can't tell winner until 6 penalties
        if (chartArrLength < 5) return;

        const userArray = [];
        const compArray = [];

        for (let i = 0; i < chartArrLength; i++) {
            if (i % 2 === 0) {
                userArray.push(chartArr[i]);
            } else {
                compArray.push(chartArr[i]);
            }
        }

        const sumUserArray =
            userArray.length !== 0 ? userArray.reduce((a, b) => a + b) : null;
        const sumCompArray =
            compArray.length !== 0 ? compArray.reduce((a, b) => a + b) : null;

        const currWinner =
            sumUserArray > sumCompArray
                ? "user"
                : sumUserArray < sumCompArray
                ? "comp"
                : null;

        // current game has no winner
        if (currWinner === null) {
            return;
        }

        const currLoser = currWinner === "user" ? "comp" : "user";
        const restPenaltiesForLoser =
            currLoser === "user" ? 5 - userArray.length : 5 - compArray.length;

        const currWinnerScore =
            currWinner === "user" ? sumUserArray : sumCompArray;
        const currLoserScore =
            currLoser === "user" ? sumUserArray : sumCompArray;

        // console.log(currWinnerScore, currLoserScore, restPenaltiesForLoser);

        // * currWinnerScore + 0 > currLoserScore + (rest of penalties for loser)
        if (chartArrLength <= 10) {
            if (currWinnerScore > currLoserScore + restPenaltiesForLoser) {
                setIsGameOver(true);
                // sound
                if (currWinner === "user") {
                    playWinSound();
                } else {
                    playLossSound();
                }
            }
        } else {
            // the one misses after both have taken p -> LOSES
            if (chartArrLength % 2 === 0) {
                setIsGameOver(true);
                // sound
                if (currWinner === "user") {
                    playWinSound();
                } else {
                    playLossSound();
                }
            }
        }
    }, [chartArr]);

    useEffect(() => {
        calculateWinner();
    }, [calculateWinner]);

    // ?env related line
    // logMessage("#### current winner = null RETURNED !!!!");

    function playKickSound() {
        const sound = kickSound ? kickSound0 : kickSound1;
        new Audio(sound).play();
        setKickSound(!kickSound);
    }

    function playWinSound() {
        setTimeout(() => {
            new Audio(winSound).play();
        }, 500);
    }

    function playLossSound() {
        setTimeout(() => {
            new Audio(lossSound).play();
        }, 500);
    }

    function playWhistle() {
        new Audio(whistle).play();
    }

    function handleSwitch() {
        setIsRoleSwitched(!isRoleSwitched);
    }

    function handleArrowClick(e) {
        if (isGameOver) {
            return;
        }
        let sideSelected;
        let sideGK;

        // play kick sound
        playKickSound();

        setIsItAfterShot(true);
        setIsWaitingUser(false);

        if (!isRoleSwitched) {
            // ball side
            sideSelected = Number(e.currentTarget.value);
            // GK side
            sideGK = Math.floor(Math.random() * 3);
        } else {
            // ball
            sideSelected = Math.floor(Math.random() * 3);
            // GK
            sideGK = Number(e.currentTarget.value);
        }

        moveBall(sideSelected);

        moveGK(sideGK);

        // Goal OR Saved
        if (sideSelected !== sideGK) {
            // Goal
            // .8s delay to prevent spoiler
            // todo: setTimeout(() => {
            if (!isRoleSwitched) {
                setScore(score + 1);
                setchartArr([...chartArr, 1]);
            } else {
                setScorePC(scorePC + 1);
                setchartArr([...chartArr, 1]);
            }
            // }, 800);
            setMessage("⚽️  GOAL  ⚽️");
            setTimeout(() => {
                setMessage("");
            }, 1000);
        } else {
            // saved
            // todo: setTimeout(() => {
            setchartArr([...chartArr, 0]);
            // }, 800);
            setMessage(
                <>
                    <GiGoalKeeper size={26} style={{ color: "#fff" }} /> SAVED{" "}
                    <GiGoalKeeper size={26} style={{ color: "#fff" }} />
                </>
            );
            setTimeout(() => {
                setMessage("");
            }, 1000);
        }

        setTimeout(() => {
            setIsItAfterShot(false);
            setIsWaitingUser(true);
            handleSwitch();
        }, 1000);

        // calculateWinner();
    }

    //#region move objects
    function moveBall(direction) {
        // options: left | up | right
        if (direction === 0) {
            setMoveBallTo("left");
        } else if (direction === 1) {
            setMoveBallTo("up");
        } else {
            setMoveBallTo("right");
        }
    }

    function moveGK(direction) {
        // options: left | up | right
        if (direction === 0) {
            setmoveGKTo("left");
        } else if (direction === 1) {
            setmoveGKTo("up");
        } else {
            setmoveGKTo("right");
        }
    }
    //#endregion

    function resetGame() {
        setchartArr([]);
        setMessage("Choose a side to shoot or jump!");
        setmoveGKTo("");
        setMoveBallTo("");
        setScore(0);
        setScorePC(0);
        setIsItAfterShot(false);
        setIsWaitingUser(true);
        setIsRoleSwitched(false);
        setKickSound(false);
    }

    function startGame() {
        setIsPlaying(true);
        setIsGameOver(false);
        setTimeout(() => {
            playWhistle();
        }, 250);
    }

    function handleShowModal() {
        setShowModal(true);
    }

    function handleNo() {
        setIsPlaying(false);
        setIsGameOver(true);
        setShowModal(false);
        resetGame();
    }

    function handleYes() {
        resetGame();
        setShowModal(false);
        // setIsGameOver(false);
        startGame();
    }

    return (
        <div>
            {isPlaying ? (
                <>
                    <Scoreboard
                        chartArr={chartArr}
                        score={score}
                        scorePC={scorePC}
                    />
                    <Field
                        handleNo={handleNo}
                        handleShowModal={handleShowModal}
                        handleYes={handleYes}
                        isGameOver={isGameOver}
                        isItAfterShot={isItAfterShot}
                        isRoleSwitched={isRoleSwitched}
                        isWaitingUser={isWaitingUser}
                        message={message}
                        moveBallTo={moveBallTo}
                        moveGKTo={moveGKTo}
                        onArrowClick={handleArrowClick}
                        score={score}
                        scorePC={scorePC}
                        showModal={showModal}
                    />
                </>
            ) : (
                <Greeting startGame={startGame} />
            )}
        </div>
    );
}
