import "./Scoreboard.css";

export default function Scoreboard({ chartArr, score, scorePC }) {
    const chartLength = chartArr.length;
    let arrayToDisplay = [];

    const page = Math.floor(chartLength / 10);

    if (chartLength > 10) {
        arrayToDisplay = chartArr.slice(page * 10);
    } else {
        // copy of an array
        arrayToDisplay = chartArr.slice();
    }

    for (let i = 0; i < chartLength; i++) {
        if (arrayToDisplay[i] === 1) {
            arrayToDisplay[i] = "⚽️";
        } else if (arrayToDisplay[i] === 0) {
            arrayToDisplay[i] = "🔴";
        }
    }

    return (
        <div className="scoreboard">
            <table>
                <thead>
                    <tr>
                        <th>Player</th>
                        <th colSpan="5">Chart</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>user</td>
                        <td>
                            {arrayToDisplay[0] !== null
                                ? arrayToDisplay[0]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[2] !== null
                                ? arrayToDisplay[2]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[4] !== null
                                ? arrayToDisplay[4]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[6] !== null
                                ? arrayToDisplay[6]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[8] !== null
                                ? arrayToDisplay[8]
                                : ""}
                        </td>
                        <td>{score}</td>
                    </tr>
                    <tr>
                        <td>comp</td>

                        <td>
                            {arrayToDisplay[1] !== null
                                ? arrayToDisplay[1]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[3] !== null
                                ? arrayToDisplay[3]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[5] !== null
                                ? arrayToDisplay[5]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[7] !== null
                                ? arrayToDisplay[7]
                                : ""}
                        </td>
                        <td>
                            {arrayToDisplay[9] !== null
                                ? arrayToDisplay[9]
                                : ""}
                        </td>
                        <td>{scorePC}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
