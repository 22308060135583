import "./Goalkeeper.css";

export default function Goalkeeper({
    moveGKTo,
    isRoleSwitched,
    isWaitingUser,
}) {
    return (
        <div
            className={`goalkeeper${
                isWaitingUser ? " waiting" : " " + moveGKTo
            }`}
        >
            <div className="gk-head">
                <div
                    className={`${!isRoleSwitched ? "gk-head-1" : "gk-head-2"}`}
                ></div>
                {/*<div className="gk-neck"> </div>*/}
            </div>

            <div className="gk-upper-body">
                <div className="gk-arm-l"> </div>
                <div
                    className="gk-torso"
                    style={isRoleSwitched ? { backgroundColor: "#afa" } : null}
                >
                    {" "}
                </div>
                <div className="gk-arm-r"> </div>
            </div>

            <div className="gk-legs">
                <div className="gk-legs-l"> </div>
                <div className="gk-legs-r"> </div>
            </div>
        </div>
    );
}
