import Ball from "../ball/Ball";
import Goal from "../goal/Goal";
import Goalkeeper from "../goalkeeper/Goalkeeper";
import {
    ImArrowUpLeft2,
    ImArrowUpRight2,
    ImArrowUp2,
    ImArrowLeft2,
    ImArrowRight2,
} from "react-icons/im";
import Message from "../message/Message";
import ModalPlayMore from "../modalPlayMore/ModalPlayMore";
import "./Field.css";

export default function Field({
    handleNo,
    handleShowModal,
    handleYes,
    isGameOver,
    isItAfterShot,
    isRoleSwitched,
    isWaitingUser,
    message,
    moveBallTo,
    moveGKTo,
    score,
    scorePC,
    showModal,
    onArrowClick,
}) {
    return (
        <div className="field">
            <div className="goal-field">
                <Goal />
                <Goalkeeper
                    moveGKTo={moveGKTo}
                    isRoleSwitched={isRoleSwitched}
                    isWaitingUser={isWaitingUser}
                />
                {isWaitingUser && !isGameOver && isRoleSwitched && (
                    <div className="arrows" id="arrow-field">
                        <button
                            className="arrowBtnGK"
                            value="0"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowLeft2 />
                        </button>
                        <button
                            className="arrowBtnGK"
                            value="1"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowUp2 />
                        </button>
                        <button
                            className="arrowBtnGK"
                            value="2"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowRight2 />
                        </button>
                    </div>
                )}
            </div>
            <div>
                {!isGameOver ? (
                    <Message isItAfterShot={isItAfterShot} message={message} />
                ) : (
                    <ModalPlayMore
                        handleNo={handleNo}
                        handleShowModal={handleShowModal}
                        handleYes={handleYes}
                        isGameOver={isGameOver}
                        score={score}
                        scorePC={scorePC}
                        showModal={showModal}
                    />
                )}
            </div>
            <div className="ball-field">
                {isWaitingUser && !isGameOver && !isRoleSwitched && (
                    <div className="arrows" id="arrow-field">
                        <button
                            className="arrowBtn"
                            value="0"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowUpLeft2 />
                        </button>
                        <button
                            className="arrowBtn"
                            value="1"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowUp2 />
                        </button>
                        <button
                            className="arrowBtn"
                            value="2"
                            onClick={(e) => onArrowClick(e)}
                        >
                            <ImArrowUpRight2 />
                        </button>
                    </div>
                )}

                <Ball isWaitingUser={isWaitingUser} moveBallTo={moveBallTo} />
            </div>
        </div>
    );
}
